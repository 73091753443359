a {
  color: $color-link;
  font-weight: bold;
  text-decoration: none;

  &:visited {
    color: $color-link-visited;
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-link-hover;
    text-decoration: none;
  }

  &:focus {
    outline: thin dotted;
  }

  &:hover,
  &:active {
    outline: 0;
  }
}

.btn {
  border-width: 4px;
  border-radius: 0;
  background-color: transparent;
  text-decoration: none;
  text-transform: uppercase;
  transition: .2s color, .2s background;
  font-weight: bold;
  letter-spacing: .2em;

  @include respond-to(xs) {
    padding: 12px 50px;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    background-color: transparent;
    box-shadow: none;
  }

  &-default {
    &,
    &:link,
    &:visited {
      border-color: $color-brand-yellow;
      color: #fff;
    }

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
      border-color: $color-brand-yellow;
      background: rgba(255, 255, 255, .7);
      color: #333;
    }
  }
}

.email-link {
  @include font-size(2.1);
  line-height: 1;

  &::before {
    display: inline-block;
    width: 25px;
    height: 19px;
    margin-top: 1px;
    margin-right: 5px;
    background: url("../img/icon_email.png") center center no-repeat;
    content: "";
  }

  .glyphicon {
    @include font-size(1.6);
    font-weight: bold;
  }
}
