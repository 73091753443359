/*
Theme Name: blank
Text Domain: blank
Author: Pace Developers
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/
/*--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  margin: .67em 0;
  font-size: 2em; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  margin: 0 2px;
  padding: .35em .625em .75em;
  border: 1px solid #c0c0c0; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-base
**/
html {
  font-size: 10px;
  box-sizing: border-box; }

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

body {
  background: #fff;
  /* Fallback for when there is no custom background color defined. */ }

body,
button,
input,
select,
textarea {
  font-size: 1rem;
  color: #5b5b5b;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  line-height: 1.5; }

b,
strong {
  font-weight: 600;
  color: #1e194d; }

blockquote,
q {
  quotes: "" ""; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: ""; }

hr {
  height: 1px;
  margin-bottom: 1.5em;
  border: 0;
  background-color: #beb7b3; }

img {
  max-width: 100%;
  /* Adhere to container width. */
  height: auto;
  /* Make sure images are scaled correctly. */ }

label {
  font-weight: 400; }
  @media (max-width: 768px) {
    label {
      font-size: 1.6rem; } }
  @media (min-width: 768px) {
    label {
      font-size: 1.6rem; } }

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%; }

ul,
ol {
  margin: .5em 0 1em;
  padding-left: 1.5em; }
  @media (max-width: 768px) {
    ul,
    ol {
      font-size: 1.6rem; } }
  @media (min-width: 768px) {
    ul,
    ol {
      font-size: 1.6rem; } }

ul {
  list-style: none;
  padding: 0; }
  ul li {
    padding-left: 35px;
    background: url("../img/bullet.png") 0 1px no-repeat; }
    ul li + li {
      margin-top: .5em; }

ol {
  list-style: decimal; }

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em; }

dt {
  font-weight: bold; }

dd {
  margin: 0 1.5em 1.5em; }

table {
  width: 100%;
  margin: 0 0 1.5em; }
  @media (max-width: 768px) {
    table {
      font-size: 1.6rem; } }
  @media (min-width: 768px) {
    table {
      font-size: 1.6rem; } }
  table th {
    padding: 10px 20px; }
    @media (max-width: 992px) {
      table th {
        padding: 5px; } }
  table td {
    padding: 2px 20px; }
    @media (max-width: 992px) {
      table td {
        padding: 2px 15px; } }

a {
  color: #f0674f;
  font-weight: bold;
  text-decoration: none; }
  a:visited {
    color: #f0674f; }
  a:hover, a:focus, a:active {
    color: #1e194d;
    text-decoration: none; }
  a:focus {
    outline: thin dotted; }
  a:hover, a:active {
    outline: 0; }

.btn {
  border-width: 4px;
  border-radius: 0;
  background-color: transparent;
  text-decoration: none;
  text-transform: uppercase;
  transition: .2s color, .2s background;
  font-weight: bold;
  letter-spacing: .2em; }
  @media (min-width: 768px) {
    .btn {
      padding: 12px 50px; } }
  .btn:hover, .btn:focus, .btn:active, .btn:focus:active {
    background-color: transparent;
    box-shadow: none; }
  .btn-default, .btn-default:link, .btn-default:visited {
    border-color: #ffe818;
    color: #fff; }
  .btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default:focus:active {
    border-color: #ffe818;
    background: rgba(255, 255, 255, 0.7);
    color: #333; }

.email-link {
  font-size: 2.1rem;
  line-height: 1; }
  .email-link::before {
    display: inline-block;
    width: 25px;
    height: 19px;
    margin-top: 1px;
    margin-right: 5px;
    background: url("../img/icon_email.png") center center no-repeat;
    content: ""; }
  .email-link .glyphicon {
    font-size: 1.6rem;
    font-weight: bold; }

p {
  margin: 1em 0; }
  @media (max-width: 768px) {
    p {
      font-size: 1.6rem; } }
  @media (min-width: 768px) {
    p {
      font-size: 1.6rem; } }

dfn,
cite,
em,
i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  font-size: 0.9375rem;
  max-width: 100%;
  margin-bottom: 1.6em;
  padding: 1.6em;
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  line-height: 1.6;
  overflow: auto; }

code,
kbd,
tt,
var {
  font-size: 0.9375rem;
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace; }

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

mark,
ins {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5em;
  clear: both;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  line-height: 1.5; }

h1 {
  font-size: 2.4rem;
  margin: 1.25em 0;
  color: #fff; }
  @media (min-width: 768px) {
    h1 {
      font-size: 3.4rem; } }
  @media (min-width: 992px) {
    h1 {
      font-size: 4.4rem; } }

h2 {
  font-size: 2.8rem;
  margin-bottom: 0;
  color: #f0674f;
  font-weight: 600; }
  @media (min-width: 992px) {
    h2 {
      font-size: 3.1rem; } }
  h2.dots {
    padding-left: 70px;
    background: url("../img/small_dots.png") 9px 40% no-repeat; }

h3 {
  font-size: 2.1rem;
  margin-top: 0;
  color: #1e194d;
  font-weight: 400; }

button,
input[type="button"],
input[type="reset"] {
  padding: .6em 1em .4em;
  border: 1px solid;
  border-radius: 3px;
  border-color: #ccc #ccc #bbb;
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 15px 17px rgba(255, 255, 255, 0.5), inset 0 -5px 12px rgba(0, 0, 0, 0.05); }
  @media (max-width: 768px) {
    button,
    input[type="button"],
    input[type="reset"] {
      font-size: 1.6rem; } }
  @media (min-width: 768px) {
    button,
    input[type="button"],
    input[type="reset"] {
      font-size: 1.6rem; } }
  button:hover,
  input[type="button"]:hover,
  input[type="reset"]:hover {
    border-color: #ccc #bbb #aaa;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8), inset 0 15px 17px rgba(255, 255, 255, 0.8), inset 0 -5px 12px rgba(0, 0, 0, 0.02); }
  button:active, button:focus,
  input[type="button"]:active,
  input[type="button"]:focus,
  input[type="reset"]:active,
  input[type="reset"]:focus {
    border-color: #aaa #bbb #bbb;
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15); }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #666; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  textarea:focus {
    color: #111; }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
  padding: 3px;
  padding-left: 10px; }

textarea {
  width: 100%;
  padding-left: 3px; }

/*--------------------------------------------------------------
3.0 Accessibility
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-module
**/

.center-x {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%); }


.center-y {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%); }

.header {
  padding: 50px 0;
  background: url("../img/hero_dot.png") center bottom no-repeat;
  background-size: cover;
  text-align: center; }
  @media (min-width: 768px) {
    .header {
      margin-bottom: 50px; } }
  .header .btn {
    font-size: 1.6rem; }
    @media (min-width: 768px) {
      .header .btn {
        font-size: 2.4rem; } }

.lede {
  font-size: 2.4rem;
  color: #1e194d; }

.content-box {
  margin-bottom: 25px;
  padding: 10px 25px 35px;
  border: 6px #000 solid; }
  .content-box.cp {
    border-color: #f0674f; }
  .content-box.dr {
    border-color: #1e194d; }
    .content-box.dr h2 {
      color: #1e194d; }
  .content-box strong {
    color: #f0674f; }

.greybg {
  margin: 0 0 25px;
  padding: 25px 0;
  background: #f9f9f9; }
  @media (min-width: 768px) {
    .greybg {
      margin: 50px 0;
      padding: 25px 0 50px; } }

.cta {
  height: 550px;
  padding-top: 135px;
  background: url("../img/large_dots.png") center center no-repeat;
  text-align: center; }
  .cta__text {
    font-size: 4.4rem;
    width: 300px;
    margin: auto;
    color: #fff;
    font-weight: 300; }
  .cta .btn {
    font-size: 1.6rem; }
    @media (min-width: 768px) {
      .cta .btn {
        font-size: 2.3rem; } }

.footer {
  margin-top: 50px; }
  .footer__logo {
    text-align: center; }
    @media (min-width: 768px) {
      .footer__logo {
        text-align: left; } }

.footer-bar {
  margin-top: 25px;
  padding: 1px;
  background: #1e194d;
  color: #fff;
  font-weight: 300; }
  .footer-bar img {
    display: inline-block;
    margin-left: 5px; }

.colophon {
  text-align: center; }
  @media (min-width: 768px) {
    .colophon {
      text-align: right; } }

/*--------------------------------------------------------------
4.0 Layout
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-layout
**/
/*--------------------------------------------------------------
5.0 Misc
--------------------------------------------------------------*/
/*
* Usage: Utility classes that do not fit anywhere in particular into SMACSS (https://smacss.com/book/)
**/
/* Text meant only for screen readers. */
.screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }
  .screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
    font-size: 0.875rem;
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    padding: 15px 23px 14px;
    border-radius: 3px;
    background-color: #f1f1f1;
    color: #5b5b5b;
    font-weight: bold;
    line-height: normal;
    text-decoration: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    z-index: 100000;
    /* Above WP toolbar. */
    clip: auto !important; }

.clear:before,
.clear:after {
  content: "";
  display: table; }

.clear:after {
  clear: both; }

/*--------------------------------------------------------------
6.0 Shaaaaaaaaaaaaame
--------------------------------------------------------------*/
/*
* Usage: Anything you aren't proud of, or hope/plan to fix later. Hopefully empty, but useful for identifying pain points when it's not.
**/
/*--------------------------------------------------------------
7.0 Overriding Bootstrap Styles
--------------------------------------------------------------*/
