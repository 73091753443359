/*
* Usage: https://smacss.com/book/type-module
**/


%center-x,
.center-x {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

%center-y,
.center-y {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.header {
  padding: 50px 0;
  background: url("../img/hero_dot.png") center bottom no-repeat;
  background-size: cover;
  text-align: center;

  @include respond-to(xs) {
    margin-bottom: 50px;
  }

  .btn {
    @include font-size(1.6);

    @include respond-to(xs) {
      @include font-size(2.4);
    }
  }
}

.lede {
  @include font-size(2.4);
  color: $color-brand-purple;
}

.content-box {
  margin-bottom: 25px;
  padding: 10px 25px 35px;
  border: 6px #000 solid;

  &.cp {
    border-color: $color-brand-orange;
  }

  &.dr {
    border-color: $color-brand-purple;

    h2 {
      color: $color-brand-purple;
    }
  }

  strong {
    color: $color-brand-orange;
  }
}

.greybg {
  margin: 0 0 25px;
  padding: 25px 0;
  background: #f9f9f9;

  @include respond-to(xs) {
    margin: 50px 0;
    padding: 25px 0 50px;
  }
}

.cta {
  height: 550px;
  padding-top: 135px;
  background: url("../img/large_dots.png") center center no-repeat;
  text-align: center;

  &__text {
    @include font-size(4.4);
    width: 300px;
    margin: auto;
    color: #fff;
    font-weight: 300;
  }

  .btn {
    @include font-size(1.6);

    @include respond-to(xs) {
      @include font-size(2.3);
    }
  }
}

.footer {
  margin-top: 50px;

  &__logo {
    text-align: center;

    @include respond-to(xs) {
      text-align: left;
    }
  }
}

.footer-bar {
  margin-top: 25px;
  padding: 1px;
  background: $color-brand-purple;
  color: #fff;
  font-weight: 300;

  img {
    display: inline-block;
    margin-left: 5px;
  }
}

.colophon {
  text-align: center;

  @include respond-to(xs) {
    text-align: right;
  }
}
