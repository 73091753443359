ul,
ol {
  @include font-base-size();
  margin: .5em 0 1em;
  padding-left: 1.5em;
}

ul {
  list-style: none;
  padding: 0;

  li {
    padding-left: 35px;
    background: url("../img/bullet.png") 0 1px no-repeat;

    + li {
      margin-top: .5em;
    }
  }
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}
