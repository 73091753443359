h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5em;
  clear: both;
  font-family: $font-heading;
  font-weight: 300;
  line-height: 1.5;
}

h1 {
  @include font-size(2.4);
  margin: 1.25em 0;
  color: #fff;

  @include respond-to(xs) {
    @include font-size(3.4);
  }

  @include respond-to(sm) {
    @include font-size(4.4);
  }
}

h2 {
  @include font-size(2.8);
  margin-bottom: 0;
  color: $color-brand-orange;
  font-weight: 600;

  @include respond-to(sm) {
    @include font-size(3.1);
  }

  &.dots {
    padding-left: 70px;
    background: url("../img/small_dots.png") 9px 40% no-repeat;
  }
}

h3 {
  @include font-size(2.1);
  margin-top: 0;
  color: $color-brand-purple;
  font-weight: 400;
}
