$color-brand-orange: #f0674f;
$color-brand-yellow: #ffe818;
$color-brand-purple: #1e194d;

$color-background-body: #fff;
$color-background-screen: #f1f1f1;
$color-background-hr: #beb7b3;
$color-background-button: #e6e6e6;
$color-background-pre: #eee;
$color-background-ins: #fff9c0;

$color-text-main: #5b5b5b;
$color-text-screen: $color-text-main;
$color-text-input: #666;
$color-text-input-focus: #111;
$color-link: $color-brand-orange;
$color-link-visited: $color-brand-orange;
$color-link-hover: $color-brand-purple;

$color-border-button: #ccc #ccc #bbb;
$color-border-button-hover: #ccc #bbb #aaa;
$color-border-button-focus: #aaa #bbb #bbb;
$color-border-input: #ccc;
$color-border-abbr: #666;
