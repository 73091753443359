$font-main: "Montserrat", sans-serif;
$font-condensed: "Montserrat", sans-serif;
$font-heading: "Montserrat", sans-serif;
$font-code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font-pre: "Courier 10 Pitch", Courier, monospace;
$font-line-height-body: 1.5;
$font-line-height-pre: 1.6;

@mixin font-base-size() {

  @media (max-width: $break-xs) {
    @include font-size(1.6);
  }

  @media (min-width: $break-xs) {
    @include font-size(1.6);
  }

}
