/*
* Usage: https://smacss.com/book/type-base
**/
html {
  font-size: 10px;
  box-sizing: border-box;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

body {
  background: $color-background-body; /* Fallback for when there is no custom background color defined. */
}

body,
button,
input,
select,
textarea {
  @include font-size(1);
  color: $color-text-main;
  font-family: $font-main;
  font-weight: normal;
  line-height: $font-line-height-body;
}

b,
strong {
  font-weight: 600;
  color: $color-brand-purple;
}

blockquote,
q {
  quotes: "" "";

  &:before,
  &:after {
    content: "";
  }
}

hr {
  height: 1px;
  margin-bottom: 1.5em;
  border: 0;
  background-color: $color-background-hr;
}

img {
  max-width: 100%; /* Adhere to container width. */
  height: auto; /* Make sure images are scaled correctly. */
}

label {
  @include font-base-size();
  font-weight: 400;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

@import "lists";
@import "tables";
@import "links";
@import "copy";
@import "headings";

@import "forms__buttons";
@import "forms__fields";
