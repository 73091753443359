button,
input[type="button"],
input[type="reset"] {

  @include font-base-size();

  padding: .6em 1em .4em;
  border: 1px solid;
  border-radius: 3px;
  border-color: $color-border-button;
  background: $color-background-button;
  color: rgba(0, 0, 0, .8);
  line-height: 1;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .8);

  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .5), inset 0 15px 17px rgba(255, 255, 255, .5), inset 0 -5px 12px rgba(0, 0, 0, .05);

  &:hover {
    border-color: $color-border-button-hover;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .8), inset 0 15px 17px rgba(255, 255, 255, .8), inset 0 -5px 12px rgba(0, 0, 0, .02);
  }

  &:active,
  &:focus {
    border-color: $color-border-button-focus;
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, .5), inset 0 2px 5px rgba(0, 0, 0, .15);
  }
}
