input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
  border: 1px solid $color-border-input;
  border-radius: 8px;
  color: $color-text-input;

  &:focus {
    color: $color-text-input-focus;
  }
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
  padding: 3px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  padding-left: 3px;
}
